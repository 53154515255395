import { GetAuthAccessRep } from '@/api-client/client';
import { RouteRecordRaw } from 'vue-router';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export const user = {
  state: {
    pages: new Array<RouteRecordRaw>(),
    permissions: new Array<GetAuthAccessRep>()
  },
  actions: {},
  getters: {
    getUser(state: any) {
      let user = cookies.get("user");
      if (user) {
        return user;
      } else {
        return {};
      }
    },
    getDisplayPages(state: any) {
      return state.pages;
    },
    getToken(state: any) {
      return cookies.get("accessToken");
    },
    getPermission(state: any) {
      return state.permissions
    }
  },
  mutations: {
    SET_NAME(state: any, name: string) {
      state.name = name;
    },
    SET_DISPLAY_PAGES(state: any, pages: RouteRecordRaw[]) {
      state.pages = pages;
    },
    SET_PERMISSION(state: any, permissions: GetAuthAccessRep[]) {
      state.permissions = permissions
    }
  }
}
