import { Modal, message } from 'ant-design-vue';
import { h } from 'vue';
import { msal } from '@/msal';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();


export class FetchInterceptor {
  public interceptors: any[] = [];
  public interceptor(fetch: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>,
    options: { input: RequestInfo; init?: RequestInit | undefined }) {
    const reversedInterceptors = this.interceptors.reduce((array, interceptor) => [...[interceptor], array])
    let promise = Promise.resolve(options)
    reversedInterceptors.forEach(({ request, requestError }: any) => {
      if (request || requestError) {
        promise = promise.then(opt => request(opt.input, opt.init), requestError)
      }
    })
    let responsePromise = promise.then(opt => fetch(opt.input, opt.init))
    reversedInterceptors.forEach(({ response, responseError }: any) => {
      if (response || responseError) {
        responsePromise = responsePromise.then((resp: Response) => {
          return response(resp)
        })
      }
    })
    return responsePromise
  }
}

export const fetchInterceptor = new FetchInterceptor()

// Request interceptors
fetchInterceptor.interceptors.push({
  request: (input: string, init: RequestInit) => {
    let headers = new Headers(init.headers)
    init.headers = headers
    headers.append('Authorization', 'bearer ' + cookies.get("accessToken"))
    return { input, init }
  }
}, {
  response: async (response: Response) => {
    if (response.status === 401) {
      Modal.warning({
        title: "Log out",
        content: "You have been logged out, try to login again.",
        okText: "Confirm",
        onOk: () => {
          msal.signOut()
        }
      })
    }
    if (response.status === 403) {
      message.error({
        content: "Error",
        duration: 5 * 1000
      })
    }
    if (response.status === 412 || response.status === 500 || response.status === 400) {
      let text = await response.text()
      let result = JSON.parse(text)
      let userMessage = Array.isArray(result.userMessage) ? result.userMessage : result.userMessage.split('</p>')
      let warningMessage =
        userMessage.map((message: string) => {
          return h('p', message.replace(/<\/?.+?>/g, ""))
        })
      Modal.warning({
        title: 'Warning',
        width: 650,
        content: h('div', {}, warningMessage)
      })
    }
    return response
  }
})

window.fetch = ((fetch) => {
  return (input: RequestInfo, init?: RequestInit | undefined) => {
    if(typeof(input) === 'string' && input.startsWith(process.env.VUE_APP_BASE_URL)) {
      init = Object.assign({}, init, { credentials: 'include' })
    }
    return fetchInterceptor.interceptor(fetch, { input, init })
  }
})(window.fetch)
