
import { msal } from "@/msal";
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  IdcardOutlined
} from "@ant-design/icons-vue";
import {
  computed,
  createApp,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  unref,
  watch,
} from "vue";
import { RouteRecordRaw, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default defineComponent({
  components: {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DownOutlined,
    IdcardOutlined 
  },
  setup() {
    const { currentRoute, push } = useRouter();
    const internalInstance = getCurrentInstance();
    let store = useStore();

    const breadcrumbs = ref<RouteRecordRaw[]>([]);
    const selectedKeys = ref<any>([]);
    const openKeys = ref<any>([]);
    const collapsed = ref<boolean>(false);

    watch(
      () => currentRoute.value,
      () => { getBreadcrumbs(); }
    );
    onMounted(() => {
      let routePage = store.getters.getDisplayPages.find((x: RouteRecordRaw) =>
        x.children?.some((b) => b.name == currentRoute.value.name)
      );
      openKeys.value = [routePage?.name];
      selectedKeys.value = [currentRoute.value.name];
      getBreadcrumbs();
    });

    const displayPages = computed(() => {
      return store.getters.getDisplayPages;
    });

    const user = computed(() => {
      return store.getters.getUser;
    });

    function getBreadcrumbs() {
      const { matched } = unref(currentRoute);
      const matchedList = matched.filter((item) => item.meta && item.meta.title);
      breadcrumbs.value = ((matchedList as any) as RouteRecordRaw[]).filter(
        (item) => item.meta && item.meta.title && !item.meta.hideBreadcrumb
      );
    }
    function handleLink(item: RouteRecordRaw) {
      push({ name: item.name });
    }
    function handelChangeMenu(menuStatus: boolean) {
      collapsed.value = menuStatus;
      store.commit("SET_SIDEBAR_COLLAPSED", menuStatus);
    }
    function logOut() {
      const keys = cookies.keys();
      keys.forEach(key => cookies.remove(key));
      internalInstance!.appContext.config.globalProperties.$msal.signOut();
    }

    return {
      breadcrumbs,
      selectedKeys,
      openKeys,
      collapsed,
      displayPages,
      user,
      handleLink,
      logOut,
      handelChangeMenu,
      currentRoute,
    };
  },
});
